import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Success from "../components/Success"

const SuccessPage = () => (
  <Layout>
    <SEO
      title="Ordine confermato!"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Success />
  </Layout>
)

export default SuccessPage
