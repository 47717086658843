import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const CoverMockup = props => {
  const { width, skew } = { ...props }
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gattivista_cover_3d.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)
  return (
    <Img
      style={{
        maxWidth: width,
        margin: `0 auto`,
        transform: skew ? `skew(0deg, 5deg)` : ``,
      }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

export default CoverMockup
