import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import CoverMockup from "../components/Images/CoverMockup"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Container = styled.section`
  background-color: #ffeb98;
  color: #ec626b;
  margin: 0 auto;
  padding-top: 1rem;
  text-align: center;
  p {
    color: #000000;
    margin: 0;
  }
  p:first-of-type {
    margin-top: 2rem;
  }
`
const Grazie = styled.div`
  font-family: "Gloria Hallelujah", sans-serif;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
`
const PerLacquisto = styled.div`
  font-family: "Gloria Hallelujah", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 0.5;
`

const StyledLink = styled(props => <Link {...props} />)`
  color: #ec626b;
  &:hover {
    color: #ec626b;
    text-decoration: none;
  }
`
const Facebook = styled(props => <OutboundLink {...props} />)`
  color: #ec626b;
  &:hover {
    color: #ec626b;
    text-decoration: none;
  }
`
const Success = () => (
  <Container>
    <Grazie>Grazie</Grazie>
    <PerLacquisto>per il tuo acquisto!</PerLacquisto>
    <p>
      Riceverai una mail di conferma dell'avvenuto pagamento e ti terremo
      aggiornato sullo stato del tuo ordine.
    </p>
    <p>
      Se hai bisogno di informazioni,{" "}
      <StyledLink to="/contatti">scrivici!</StyledLink>
    </p>
    <p>
      Puoi seguire gli aggiornamenti sulla{" "}
      <Facebook href="https://www.facebook.com/La-Gattivista-2377680402461690/">
        nostra pagina Facebook
      </Facebook>
      !
    </p>
    <CoverMockup width={`500px`} />
  </Container>
)
export default Success
